import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import { adCopyCpLabels } from "../../../../../../../../../label/ad-copy/cp";
import { Radios } from "../../../../../../../../../react-components/lu-component/src/index";
import type { AdCopyCP, AdCopySimilarProductInformation } from "../../../../../../../../../server/models/ad-copy/cp";
import { adCopyProductTypeOptions, isAdCopyProductType } from "../../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../../server/models/ad-copy/job";
import type { Brand } from "../../../../../../../../../server/models/brand";
import type { Category } from "../../../../../../../../../server/models/category";
import type { ErrorObject } from "../../../../../../../../../server/types/error";
import { getErrorObject } from "../../../../../../../lib/error";
import { ConfirmButton } from "../../../../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../../../../parts/font";
import { InputRow, TextRow } from "../../../../../../parts/input-row";
import { AdCopyReportTitle } from "../../../parts/report-title";
import { AdCopyCpActualValueTableInput } from "./ad-copy-cp-actual-value-table-input";
import { AdCopySimilarProductInfoSetting } from "./similar-product-info";

type Props = {
	brands: Brand[];
	categories: Category[];
	cp: Partial<AdCopyCP>;
	errors: ErrorObject | undefined;
	hasEditRole: boolean;
	job: AdCopyJob;
	onChange: <K extends keyof AdCopyCP>(key: K, value: AdCopyCP[K]) => void;
	onClear: () => void;
	onSubmit: () => void;
};

export const AdCopyCPSetting = React.memo(
	({ brands, categories, cp, errors, hasEditRole, job, onChange, onClear, onSubmit }: Props) => {
		const { actualValue, reason, similarProductInfo, type } = cp;

		const canSubmit = React.useMemo(() => {
			if (cp.type === "currentProduct") {
				return !!cp.actualValue && !!cp.reason;
			}

			return !!cp.actualValue && !!cp.similarProductInfo?.category && !!cp.similarProductInfo.brandId && !!cp.reason;
		}, [cp]);

		const disabled = !!cp?._id && !!cp.correctionValues?.result;

		return (
			<>
				<Row>
					<Col md={10}>
						<AdCopyReportTitle>現行品/類似品のCP実績設定</AdCopyReportTitle>
					</Col>
				</Row>

				{!!cp._id && (
					<Row style={{ marginBottom: "0.8rem" }}>
						<Col md={{ span: 2 }}>
							<ConfirmButton
								body="CP実績設定のクリアをします。よろしいでしょうか。"
								buttonText="クリア"
								onOk={onClear}
								title="設定値のクリア"
								variant="outline-secondary"
							>
								CP実績設定のクリア
							</ConfirmButton>
						</Col>
					</Row>
				)}

				<Row>
					<Col md={8}>
						{/* CP実績（万個） */}
						{/* ※上市後1年間のトライアル売上数量 */}
						<AdCopyCpActualValueTableInput
							disabled={disabled}
							errors={errors}
							job={job}
							onChange={onChange}
							value={actualValue}
						/>

						<TextRow
							as="textarea"
							disabled={disabled}
							errors={errors}
							id="reason"
							name="reason"
							onChange={(value) => {
								onChange("reason", value);
							}}
							rowName={
								<>
									<div>{adCopyCpLabels.reason}</div>

									<div>
										<TextRed>※CP実績を入力した場合必須</TextRed>
									</div>
								</>
							}
							value={reason?.toString() ?? ""}
						/>

						<InputRow errors={errors} name="salesScale" rowName={adCopyCpLabels.type}>
							<Radios
								checked={type}
								choices={adCopyProductTypeOptions}
								disabled={disabled}
								name="salesScale"
								onChange={(value) => isAdCopyProductType(value) && onChange("type", value)}
							/>
						</InputRow>

						{type === "similarProduct" && (
							<AdCopySimilarProductInfoSetting
								brands={brands}
								categories={categories}
								disabled={disabled}
								errors={getErrorObject("similarProductInfo", errors)}
								onChange={(similarProductInfo: AdCopySimilarProductInformation) => {
									onChange("similarProductInfo", similarProductInfo);
								}}
								similarProductInfo={similarProductInfo ?? {}}
							/>
						)}
					</Col>
				</Row>

				{hasEditRole && (
					<Row>
						{disabled ? (
							<Col md={{ offset: 2, span: 8 }}>
								<TextRed>値を変更する場合はクリアボタンを押して下さい。</TextRed>
							</Col>
						) : (
							<Col md={{ offset: 3, span: 2 }}>
								<Button disabled={!canSubmit} onClick={onSubmit} variant="secondary">
									CP値 更新
								</Button>
							</Col>
						)}
					</Row>
				)}
			</>
		);
	},
);
