import * as React from "react";
import styled from "styled-components";

import { isExistingBrand } from "../../../../../../../../server/lib/ad-copy/common";
import type { AdCopyActivityWithBrand } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCP } from "../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../server/models/ad-copy/job";
import type { AdCopyCorrectionValue } from "../../../../../../../../server/models/ad-copy/simulation-value";
import type { Brand } from "../../../../../../../../server/models/brand";
import type { Category } from "../../../../../../../../server/models/category";
import type { ErrorObject } from "../../../../../../../../server/types/error";
import type { AdCopySimulationBrandData } from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import { AdCopyCPParameterSetting } from "./parameter";
import { AdCopyCPSetting } from "./setting";

const WrapArea = styled.div`
	margin: 0.8rem;
`;

export type AdCopyCPPageProps = {
	activity: AdCopyActivityWithBrand;
	brands: Brand[];
	categories: Category[];
	constValue: AdCopySimulationBrandData;
	cp: Partial<AdCopyCP>;
	errors: ErrorObject | undefined;
	hasEditRole: boolean;
	job: AdCopyJob;
	maxRecognition: number;
	onChange: <K extends keyof AdCopyCP>(key: K, value: AdCopyCP[K]) => void;
	onClear: () => void;
	onSubmit: () => void;
	onSubmitCPCorrectionValues: () => void;
};

export const AdCopyCPPage = React.memo(
	({
		activity,
		brands,
		categories,
		constValue,
		cp,
		errors,
		hasEditRole,
		job,
		maxRecognition,
		onChange,
		onClear,
		onSubmit,
		onSubmitCPCorrectionValues,
	}: AdCopyCPPageProps) => {
		const onChangeRatio = React.useCallback(
			<K extends keyof AdCopyCorrectionValue>(name: K, value: AdCopyCorrectionValue[K]) => {
				console.log({ ...(cp.correctionValues ?? {}), [name]: value });

				onChange("correctionValues", {
					...(cp.correctionValues ?? {}),
					[name]: value,
				} as any);
			},
			[cp, onChange],
		);

		const isSimilarProductExistingBrand = React.useMemo(() => {
			if (cp.type === "currentProduct") {
				return activity.isExistingBrand;
			}

			const brand = brands.find((b) => b._id === cp.similarProductInfo?.brandId);

			const category = cp.similarProductInfo?.category;

			if (brand == null || category == null) {
				return;
			}

			return isExistingBrand(
				{
					...activity,
					category,
				},
				brand,
			);
		}, [activity, brands, cp.similarProductInfo?.brandId, cp.similarProductInfo?.category, cp.type]);

		return (
			<WrapArea>
				{/* 現行品/類似品のCP実績設定 */}
				<AdCopyCPSetting
					brands={brands}
					categories={categories}
					cp={cp}
					errors={errors}
					hasEditRole={hasEditRole}
					job={job}
					onChange={onChange}
					onClear={onClear}
					onSubmit={onSubmit}
				/>

				{/* 現行品/類似品の変数補整 */}
				<AdCopyCPParameterSetting
					constValue={constValue}
					cp={cp}
					hasEditRole={hasEditRole}
					isExistingBrand={isSimilarProductExistingBrand ?? false}
					job={job}
					maxRecognition={maxRecognition}
					onChangeRatio={onChangeRatio}
					onSubmit={onSubmitCPCorrectionValues}
				/>
			</WrapArea>
		);
	},
);
