/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Alert, Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import styled from "styled-components";

import type { Column, FormInputGroupsProps } from "../../../../../../../react-components/lu-component/src/index";
import { FormInputGroups, MultipleText } from "../../../../../../../react-components/lu-component/src/index";
import { adCopyEvaluationItemMatrixChildQuestion } from "../../../../../../../server/lib/ad-copy/enquete/config";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { useDisclosure } from "../../../../../hooks/use-disclosure";
import { canSetDemandForecast } from "../../../../../lib/ad-copy/activity";
import { getErrorMessage } from "../../../../../lib/error";
import { AlertText } from "../../../../parts/font";
import { List } from "../../../../parts/list";
import { Title } from "../../../../parts/title";
import { CurrentProductAlertModal } from "./current-product-alert-modal";

const AlertMessage = styled.div`
	color: red;
`;

const IndentArea = styled.div`
	margin-left: 25px;
`;

const WrapArea = styled.div`
	.col-hasBenchmark,
	.col-hasCurrentProduct {
		margin-left: 10px;
	}
`;

type Props = {
	adCopyCanDemandForecastNeeded: boolean;
	cantEditMessage?: string;
	errors: ErrorObject | undefined;
	evaluationItem: string[];
	job: Partial<AdCopyJob>;
	needCurrentProduct: boolean;
	onChangeEvaluationItem: (items: string[]) => void;
	onChangeJob: <T extends keyof AdCopyJob>(name: T, value: AdCopyJob[T]) => void;
	onSubmit: () => void;
};

export const AdCopyJobCreatePage = React.memo((props: Props) => {
	const {
		adCopyCanDemandForecastNeeded,
		cantEditMessage,
		errors,
		evaluationItem,
		job,
		needCurrentProduct,
		onChangeEvaluationItem,
		onChangeJob,
		onSubmit,
	} = props;

	const isRetest = !!job.retestJobId;

	const shouldShowCurrentProductForms =
		(job.isDemandForecastNeeded ?? false) &&
		(job.hasCurrentProduct ||
			(isRetest && job.currentProductRecognitionRate != null && job.currentProductTrialSalesVolume != null));

	const jobColumns = React.useMemo(() => {
		const defaultColumns: Column[][] = [
			[
				{
					choices: [
						{ label: "1案", value: 1 },
						{ label: "2案", value: 2 },
						{ label: "3案", value: 3 },
					],
					col: 5,
					label: "自社テスト品",
					name: "numOfTests",
					type: "radios",
				},
			],
			[
				{
					choices: [
						{ label: "キービジュアル（KV）なし", value: false },
						{ label: "キービジュアル（KV）あり", value: true },
					],
					col: 5,
					disabled: isRetest,
					label: "呈示物パターン",
					name: "hasKeyVisual",
					type: "radios",
				},
			],
			[
				{
					choices: [
						{ label: "あり", value: true },
						{ label: "なし", value: false },
					],
					col: 2,
					disabled: isRetest,
					isShow: () => canSetDemandForecast && adCopyCanDemandForecastNeeded,
					label: "需要予測の有無",
					name: "isDemandForecastNeeded",
					type: "radios",
				},
				{
					col: 5,
					disabled: isRetest,
					isShow: () => shouldShowCurrentProductForms,
					label: (
						<div>
							現行品の想定認知率(%)
							<br />
							<AlertText>（99％以下の整数で入力）</AlertText>
						</div>
					),
					name: "currentProductRecognitionRate",
					type: "number",
				},
				{
					col: 5,
					disabled: isRetest,
					isShow: () => shouldShowCurrentProductForms,
					label: (
						<div>
							現行品に相当する製品の上市後1年間
							<br />
							トライアル売上数量(SLI,SCIに基づく)（万個）
						</div>
					),
					name: "currentProductTrialSalesVolume",
					type: "decimal",
				},
			],
			[
				{
					col: 6,
					label: (
						<div>
							調査開始日時 <AlertMessage>※ 調査開始から4日後が最長の終了予定日となります。</AlertMessage>
						</div>
					),
					name: "startDatetime",
					type: "datetimepicker",
				},
			],
		];

		const otherConceptSettings: Column[][] = [
			[
				{
					col: 6,
					label: "ベンチマーク",
					name: "head",
					type: "label",
				},
			],
			[
				{
					choices: [
						{ label: "あり", value: true },
						{ label: "なし", value: false },
					],
					col: 12,
					disabled: isRetest || needCurrentProduct,
					label: (
						<div>
							現行品
							<AlertMessage>
								※現行品について、1回目調査で実施した場合、調査せず、1回目調査結果と比較になります。
							</AlertMessage>
						</div>
					),
					name: "hasCurrentProduct",
					type: "radios",
				},
			],
			[
				{
					choices: [
						{ label: "あり", value: true },
						{ label: "なし", value: false },
					],
					col: 5,
					disabled: isRetest,
					label: "競合品",
					name: "hasBenchmark",
					type: "radios",
				},
				{
					disabled: isRetest,
					isShow: (data: AdCopyJob) => data.hasBenchmark,
					label: <>競合品ありの場合はブランド名と商品名を入力してください</>,
					name: "benchmarkName",
					type: "text",
				},
			],
		];

		return [
			//
			...otherConceptSettings,
			...defaultColumns,
		];
	}, [adCopyCanDemandForecastNeeded, isRetest, needCurrentProduct, shouldShowCurrentProductForms]);

	const currentProductAlertModalDisclosure = useDisclosure();

	const handleSubmit = () => {
		// 需要予測あり＆現行品設定なし、で設定されたケースが発生した場合は、
		// システム側で「現行品が設定されていないため、現行品実績による補整は需要予測に反映されません。現行品は設定しなくてよいですか？」（アラート文は仮）
		// といったポップアップを入れるなど確認ステップを挟む
		if (!isRetest && job.isDemandForecastNeeded && job.hasCurrentProduct != null && !job.hasCurrentProduct) {
			currentProductAlertModalDisclosure.open();
		} else {
			onSubmit();
		}
	};

	return (
		<>
			<CurrentProductAlertModal
				onClose={currentProductAlertModalDisclosure.close}
				onSubmit={onSubmit}
				show={currentProductAlertModalDisclosure.isOpen}
			/>

			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant="danger">{cantEditMessage}</Alert>
				</div>
			)}

			<div className="m-3">
				<div className="h3">■{job._id ? "ジョブ更新" : isRetest ? "再調査登録" : "ジョブ新規登録"}</div>
			</div>

			<div className="m-3">
				<Row>
					<Col md={12}>
						<Title>基本設定</Title>
					</Col>
				</Row>

				<IndentArea>
					<Row>
						<Col md={8}>
							<Row>
								<Col md={12}>
									<WrapArea>
										<FormInputGroups
											columns={jobColumns}
											data={job}
											errors={errors?.["job"] && typeof errors["job"] === "object" ? errors["job"] : undefined}
											onChange={onChangeJob as FormInputGroupsProps["onChange"]}
										/>
									</WrapArea>
								</Col>
							</Row>
						</Col>
					</Row>
				</IndentArea>

				<Row>
					<Col md={12}>
						<Title>カスタム設定</Title>
					</Col>
				</Row>

				<IndentArea>
					<Row>
						<Col md={12}>
							<FormGroup style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>
								<FormLabel>印象項目設定</FormLabel>

								<Row>
									<Col md={4}>
										<div>
											標準項目 <AlertMessage>※変更や削除はできません</AlertMessage>
										</div>

										<List list={adCopyEvaluationItemMatrixChildQuestion.map((item) => item.quetitle)} />
									</Col>

									<Col md={8}>
										<div>
											追加項目（任意/5つまで）<AlertMessage>文字数を30文字以内としてください</AlertMessage>
										</div>

										<MultipleText
											hasAddEvent
											max={5}
											onChange={onChangeEvaluationItem}
											sentenceOptions={{ max: 30 }}
											values={evaluationItem}
										/>

										{errors && "evaluationItem" in errors && (
											<Feedback style={{ display: "block" }} type="invalid">
												{getErrorMessage("evaluationItem", errors)}
											</Feedback>
										)}
									</Col>
								</Row>
							</FormGroup>
						</Col>
					</Row>
				</IndentArea>

				<Row>
					<Col md={{ offset: 4, span: 2 }}>
						{cantEditMessage ? (
							<Button className="w-100" disabled variant="secondary">
								{job._id ? "更新" : "登録"}できません
							</Button>
						) : (
							<Button className="w-100" onClick={handleSubmit} variant="secondary">
								{job._id ? "更新" : "登録"}
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
});
