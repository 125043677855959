import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopySimulationResponse } from "../../../../../../server/types/request/ad-copy/report/simulation";
import type { AdCopySimulationPageProps } from "../../../../components/pages/ad-copy/report/simulation";
import { AdCopySimulationPage } from "../../../../components/pages/ad-copy/report/simulation";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeDwnloadImage, makeError, makeErrorBlob, put, remove } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/simulation";

// TODO: あとで消す
// import { mockResponse } from "./xxx";

export const AdCopySimulationContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, ...sProps } = state;

	const { jobId } = useParams<{
		jobId: string;
	}>();

	// TODO: あとで消す
	console.log({ state });

	const refetch = React.useCallback(() => {
		// TODO: あとで消す
		// dispatch({ payload: mockResponse, type: "loadData" });

		get<GetAdCopySimulationResponse>(`${adCopyEndpoint.simulation}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadData" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	React.useEffect(() => refetch(), [refetch]);

	const onChangeRatio = React.useCallback<AdCopySimulationPageProps["onChangeRatio"]>((target, name, value) => {
		dispatch({ payload: { name, target, value }, type: "changeRatio" });
	}, []);

	const onSimulation = React.useCallback<AdCopySimulationPageProps["onSimulation"]>(
		(conceptType) => {
			const html = document.getElementsByClassName("simulation-table").item(0)?.outerHTML;

			dispatch({ payload: true, type: "changeLoading" });
			const correctionValue = sProps.simulationPredictionData[conceptType]?.correctionValue;

			makeDwnloadImage(
				`${adCopyEndpoint.simulation}/${jobId}`,
				{ correctionValue, html },
				`${sProps.job?.jobNum}_executive_summary.png`,
			)
				.then(() => dispatch({ payload: true, type: "downloadSimulation" }))
				.catch(async (error) => {
					dispatch({ payload: await makeErrorBlob(error, true), type: "changeMessageInfo" });
				});
		},
		[sProps.simulationPredictionData, sProps.job?.jobNum, jobId],
	);

	const onSubmitCP = React.useCallback<AdCopySimulationPageProps["onSubmitCP"]>(() => {
		const cp = sProps.cp;

		if (cp == null) {
			return;
		}

		const { actualValue, reason, similarProductInfo, type } = cp;

		dispatch({ payload: true, type: "changeLoading" });

		put<GetAdCopySimulationResponse>(`${adCopyEndpoint.cp}/${jobId}`, { actualValue, reason, similarProductInfo, type })
			.then((response) => dispatch({ payload: response.data, type: "updateData" }))
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId, sProps.cp]);

	const onSubmitCPCorrectionValues = React.useCallback<AdCopySimulationPageProps["onSubmitCPCorrectionValues"]>(() => {
		const cp = sProps.cp;

		if (cp == null) {
			return;
		}

		const { correctionValues } = cp;

		dispatch({ payload: true, type: "changeLoading" });

		put<GetAdCopySimulationResponse>(`${adCopyEndpoint.cpCorrectionValues}/${jobId}`, correctionValues)
			.then((response) => dispatch({ payload: response.data, type: "updateData" }))
			.catch((error) => {
				dispatch({ payload: makeError(error, true), type: "changeMessageInfo" });
			});
	}, [jobId, sProps.cp]);

	const onChangeCP = React.useCallback<AdCopySimulationPageProps["onChangeCP"]>((name, value) => {
		dispatch({ payload: { name, value }, type: "changeCP" });
	}, []);

	const onClearCP = React.useCallback(() => {
		remove<GetAdCopySimulationResponse>(`${adCopyEndpoint.cp}/${jobId}`)
			.then((response) => dispatch({ payload: response.data, type: "clearCPData" }))
			.catch((error) => {
				dispatch({ payload: makeError(error, true), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onSimulationSave = React.useCallback<AdCopySimulationPageProps["onSimulationSave"]>(
		(conceptType) => {
			dispatch({ payload: true, type: "changeLoading" });
			const correctionValue = sProps.simulationPredictionData[conceptType]?.correctionValue;

			put(`${adCopyEndpoint.simulation}/${jobId}`, { correctionValue })
				.then(() => dispatch({ payload: { isSuccess: true, message: "保存が完了しました。" }, type: "saveSimulation" }))
				.catch((error) => {
					dispatch({ payload: makeError(error, true), type: "saveSimulation" });
				});
		},
		[sProps.simulationPredictionData, jobId],
	);

	const setChanged = React.useCallback((changed: boolean) => dispatch({ payload: changed, type: "setChanged" }), []);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="保存処理" />

			{sProps.activity && sProps.constValue && sProps.evaluation && sProps.job && sProps.onsByPurchaseIntent && (
				<AdCopySimulationPage
					{...sProps}
					activity={sProps.activity}
					constValue={sProps.constValue}
					errors={info.errors}
					evaluation={sProps.evaluation}
					job={sProps.job}
					loginAccount={loginAccount}
					onChangeCP={onChangeCP}
					onChangeRatio={onChangeRatio}
					onClearCP={onClearCP}
					onSimulation={onSimulation}
					onSimulationSave={onSimulationSave}
					onSubmitCP={onSubmitCP}
					onSubmitCPCorrectionValues={onSubmitCPCorrectionValues}
					onsByPurchaseIntent={sProps.onsByPurchaseIntent}
					refetch={refetch}
					setChanged={setChanged}
				/>
			)}
		</>
	);
};
