import { Labels } from "..";
import { AdCopyCP, AdCopySimilarProductInformation } from "../../server/models/ad-copy/cp";

export const adCopySimilarProductParameterLabels: Labels<AdCopySimilarProductInformation> = {
	category: "カテゴリ",
	brandId: "ブランド",
	recognitionRate: "認知率",
};

export const adCopyCpLabels: Labels<AdCopyCP> = {
	_id: "コンセプト",
	jobId: "ジョブID",
	actualValue: "CP実績（万個）",
	type: "タイプ",
	similarProductInfo: "現行品/類似品の変数補整",
	reason: "CP実績の定義（対象期間や抽出元など）を記載",
	cpSimulation: "現行・類似品需要予測",
	correctionValues: "補正値",
};
