import { ObjectId } from "mongodb";
import { Category } from "../category";
import { AdCopyCorrectionValue } from "./simulation-value";
import { SimulationData } from "./output";
import { AdCopySimulationBrandData } from "../../types/request/ad-copy/report/simulation";

export const adCopyProductType = {
	currentProduct: "現行品",
	similarProduct: "類似品",
} as const;

export type AdCopyProductType = keyof typeof adCopyProductType;
export const adCopyProductTypes = Object.keys(adCopyProductType) as AdCopyProductType[];

export const isAdCopyProductType = (value: unknown): value is AdCopyProductType => {
	if (typeof value !== "string") return false;
	return adCopyProductTypes.includes(value as any);
};

export const adCopyProductTypeOptions = (Object.entries(adCopyProductType) as [AdCopyProductType, string][]).map(
	([value, label]) => ({
		value,
		label,
	}),
);

/** 推定値 */
export type AdCopyCorrectedValue = {
	total: number;
	trial: number;
	repeat: number;
	localOther: number;
	inbound: number;
	toTargetSalesVolume?: number; // 対目標値
};

export type AdCopyCpCorrectedResult = AdCopyCorrectedValue & {
	correctionValue: number; // 補正係数
};

/** 補正値 & 推定値 */
export type AdCopyCPCurrectValues = AdCopyCorrectionValue & {
	result: {
		test1: AdCopyCpCorrectedResult;
		test2?: AdCopyCpCorrectedResult;
		test3?: AdCopyCpCorrectedResult;
	};
	ons: AdCopySimulationBrandData; // test1を元に算出したワンナンバースコア
};

/** 現行品/類似品の変数補整 */
export type AdCopySimilarProductInformation = {
	brandId: ObjectId | string;
	category: Category;
	recognitionRate: number;
};

/** 現行品のCP実績反映後の需要予測結果 */
export type AdCopyCPSimulation = {
	test1: SimulationData;
	test2?: SimulationData;
	test3?: SimulationData;
};

export type AdCopyCP = {
	_id?: ObjectId | string;
	jobId: ObjectId | string;
	actualValue: number; // CP実績
	type: AdCopyProductType;
	reason: string;
	similarProductInfo?: AdCopySimilarProductInformation;
	cpSimulation: AdCopyCPSimulation;
	correctionValues?: AdCopyCPCurrectValues;
};
