import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";

import { adCopyCpLabels } from "../../../../../../../../../label/ad-copy/cp";
import type { AdCopyCP } from "../../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../../../lib/error";
import { TextRed } from "../../../../../../parts/font";
import { NumberInput } from "../../../../../../parts/input-row";

const name = "actualValue";

type Props = {
	disabled: boolean;
	errors: ErrorObject | undefined;
	job: AdCopyJob;
	onChange: <K extends keyof AdCopyCP>(key: K, value: AdCopyCP[K]) => void;
	value: number | undefined;
};

export const AdCopyCpActualValueTableInput = React.memo((props: Props) => {
	const { disabled, errors, job, onChange, value } = props;

	return (
		<Row className="m-2">
			<Col className="text-end" lg={4}>
				<div
					style={{
						whiteSpace: "pre",
					}}
				>
					<span>{adCopyCpLabels.actualValue}</span>

					<br />

					<TextRed>※上市後1年間のトライアル売上数量</TextRed>
				</div>
			</Col>

			<Col lg={3}>
				<table>
					<thead>
						<tr>
							<th
								style={{
									backgroundColor: "#ddd",
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
									width: "100px",
								}}
							>
								初期値
							</th>

							<th
								style={{
									backgroundColor: "#ddd",
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
									width: "100px",
								}}
							>
								補整値
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td
								style={{
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
								}}
							>
								{job.currentProductTrialSalesVolume}
							</td>

							<td
								style={{
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
								}}
							>
								<NumberInput
									//
									disabled={disabled}
									errors={errors}
									id="actualValue"
									name="actualValue"
									onChange={(value) => {
										onChange("actualValue", value);
									}}
									rowName=""
									value={value?.toString() ?? ""}
								/>
							</td>
						</tr>
					</tbody>
				</table>

				{!!errors?.[name] && (
					<Feedback
						style={{
							display: "block",
						}}
						type="invalid"
					>
						{getErrorMessage(name, errors)}
					</Feedback>
				)}
			</Col>

			<Col lg={5}>
				<TextRed>※初期値はアクティビティ登録時において入力した</TextRed>

				<br />

				<TextRed>
					「現行刺激に相当する製品の上市後1年間トライアル売上数量(SLI,SCIに基づく)」の数値が表示されています
				</TextRed>
			</Col>
		</Row>
	);
});
