import type { Option } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyJobCreateAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	adCopyCanDemandForecastNeeded: boolean;
	cantEditMessage?: string;
	evaluationItem: string[];
	info: MessageInfo;
	job: Partial<AdCopyJob>;
	jobOptions: Option[];
	loading: boolean;
	needCurrentProduct: boolean;
	prompt: boolean;
};

export const initState: State = {
	adCopyCanDemandForecastNeeded: false,
	evaluationItem: [],
	info: {
		isSuccess: true,
		message: "",
	},
	job: {},
	jobOptions: [],
	loading: true,
	needCurrentProduct: false,
	prompt: false,
};

export const reducer = (state: State, action: AdCopyJobCreateAction): State => {
	switch (action.type) {
		case "loadJob": {
			return {
				...state,
				loading: false,
				...action.payload,
				job: {
					...state.job,
					...action.payload.job,
					benchmarkName: action.payload.job?.benchmarkName ?? action.payload.benchmarkName,
					currentProductRecognitionRate:
						action.payload.job?.currentProductRecognitionRate ?? action.payload.originCurrentProductRecognitionRate,
					currentProductTrialSalesVolume:
						action.payload.job?.currentProductTrialSalesVolume ?? action.payload.originCurrentProductTrialSalesVolume,
					hasBenchmark:
						action.payload.job?.hasBenchmark ?? (action.payload.benchmarkName == null ? state.job.hasBenchmark : true),
					hasCurrentProduct:
						action.payload.job?.hasCurrentProduct ?? (action.payload.needCurrentProduct || state.job.hasCurrentProduct),
					hasKeyVisual: action.payload.job?.hasKeyVisual ?? action.payload.hasKeyVisual ?? state.job.hasKeyVisual,
					isDemandForecastNeeded:
						action.payload.job?.isDemandForecastNeeded ??
						action.payload.originDemandForecastNeeded ??
						state.job.isDemandForecastNeeded,
				},
			};
		}

		case "updateJob":
			return {
				...state,
				...action.payload,
				info: {
					isSuccess: true,
					message: `${state.job._id ? "更新" : "作成"}が完了しました。`,
				},
				loading: false,
				prompt: false,
			};

		case "changeJob": {
			const { name, value } = action.payload;

			// 需要予測が不要の場合、現行品の関連する値を削除
			if ((name === "isDemandForecastNeeded" || name === "hasCurrentProduct") && (value == null || value === false)) {
				return {
					...state,
					job: {
						...state.job,
						// NOTE: undefined だと Optional のフィールドが更新されないので、null で更新する
						currentProductRecognitionRate: null,
						currentProductTrialSalesVolume: null,
						[name]: false,
					},
					prompt: true,
				};
			}

			return {
				...state,
				job: {
					...state.job,
					[name]: value,
				},
				prompt: true,
			};
		}

		case "changeEvaluationItem":
			return {
				...state,
				evaluationItem: action.payload,
				prompt: true,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
