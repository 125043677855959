import type { GetAdCopySimulationResponse } from "../../../../../../server/types/request/ad-copy/report/simulation";
import type { AdCopySimulationAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = Partial<
	Pick<
		GetAdCopySimulationResponse,
		| "activity"
		| "constValue"
		| "evaluation"
		| "job"
		| "onsByPurchaseIntent"
		| "trialSalesVolumeBeforeCorrectionByCurrentProduct"
	>
> &
	Pick<
		GetAdCopySimulationResponse,
		| "appearanceByStrategyTarget"
		| "brands"
		| "categories"
		| "cp"
		| "cpMaxRecognition"
		| "goalSalesVolume"
		| "hasCurrentProduct"
		| "maxRecognition"
		| "simulationPredictionData"
	> & {
		confirmMessage: string | undefined;
		info: MessageInfo;
		loading: boolean;
	};

export const initState: State = {
	appearanceByStrategyTarget: 0,
	brands: [],
	categories: [],
	confirmMessage: undefined,
	cpMaxRecognition: 0,
	goalSalesVolume: 0,
	hasCurrentProduct: false,
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	maxRecognition: 0,
	simulationPredictionData: {},
};

export const reducer = (state: State, action: AdCopySimulationAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				confirmMessage: undefined,
				loading: false,
				...action.payload,
			};

		case "updateData":
			return {
				...state,
				confirmMessage: undefined,
				loading: false,
				...action.payload,
				info: { isSuccess: true, message: "保存が完了しました。" },
			};

		case "clearCPData":
			return {
				...state,
				confirmMessage: undefined,
				loading: false,
				...action.payload,
				info: {
					isSuccess: true,
					message: "CP実績補整のクリアが完了しました。",
				},
			};

		case "changeRatio": {
			const { name, target, value } = action.payload;

			return {
				...state,
				confirmMessage: "保存せずにページを移動すると入力内容がクリアされます。移動してよろしいでしょうか。",
				simulationPredictionData: {
					...state.simulationPredictionData,
					[target]: {
						...state.simulationPredictionData[target],
						correctionValue: {
							...state.simulationPredictionData[target]?.correctionValue,
							[name]: value,
						},
					},
				},
			};
		}

		case "changeCP": {
			const ret: State["cp"] = {
				...(state.cp ?? {}),
				[action.payload.name]: action.payload.value,
			};

			if (action.payload.name === "type" && action.payload.value === "currentProduct") {
				delete ret.similarProductInfo;
			}

			if (action.payload.name !== "correctionValues") {
				delete ret._id;
				delete ret.cpSimulation;
				delete ret.correctionValues;
			}

			return {
				...state,
				confirmMessage:
					"CP値を入力し、推計値算出まで実施しないとCP実績補整は提案フォーマットに反映されません。\n移動してよろしいでしょうか。",
				cp: ret,
			};
		}

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "downloadSimulation":
			return {
				...state,
				confirmMessage: undefined,
				loading: false,
			};

		case "saveSimulation":
			return {
				...state,
				confirmMessage: action.payload.isSuccess ? undefined : state.confirmMessage,
				info: action.payload,
				loading: false,
			};

		case "setChanged":
			return {
				...state,
				confirmMessage: action.payload ? state.confirmMessage : undefined,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };
	}
};
