/* eslint-disable react/jsx-newline */

import type { ObjectId } from "mongodb";
import * as React from "react";

import { adCopySimilarProductParameterLabels } from "../../../../../../../../../label/ad-copy/cp";
import {
	getAdCopyMaxRecognition,
	getRecognitionByCategoryAndBrand,
} from "../../../../../../../../../server/lib/ad-copy/common";
import type { AdCopySimilarProductInformation } from "../../../../../../../../../server/models/ad-copy/cp";
import type { Brand } from "../../../../../../../../../server/models/brand";
import type { Category } from "../../../../../../../../../server/models/category";
import type { ErrorObject } from "../../../../../../../../../server/types/error";
import { castString } from "../../../../../../../lib/cast";
import { AdCopyCategoryRecognitionTable } from "../../../../../../parts/ad-copy/recognition-table/category";
import { AlertText } from "../../../../../../parts/font";
import { SelectRow, TextRow } from "../../../../../../parts/input-row";
import { BrandRecognitionTable } from "../../../../../../parts/recognition-table/brand";

type Props = {
	brands: Brand[];
	categories: Category[];
	disabled: boolean;
	errors: ErrorObject;
	onChange: (value: Partial<AdCopySimilarProductInformation>) => void;
	similarProductInfo: Partial<AdCopySimilarProductInformation>;
};

const findById = <
	T extends {
		_id?: ObjectId | string;
	},
>(
	id: ObjectId | string | undefined,
	values: T[],
) => (id ? values.find((v) => v._id && v._id.toString() === id) : undefined);

const makeOptions = <
	T extends {
		_id?: ObjectId | string;
		name: string;
	},
>(
	values: T[],
) =>
	values.map(({ _id, name }) => ({
		label: name,
		value: _id?.toString() ?? "",
	}));

export const AdCopySimilarProductInfoSetting = React.memo(
	({ brands, categories, disabled, errors, onChange, similarProductInfo }: Props) => {
		const categoryOptions = React.useMemo(() => makeOptions(categories), [categories]);
		const brandOptions = React.useMemo(() => makeOptions(brands), [brands]);

		const brand = React.useMemo(
			() => findById(similarProductInfo.brandId, brands),
			[brands, similarProductInfo.brandId],
		);

		const category = similarProductInfo.category;

		const handleChangeCategory = React.useCallback(
			(value: string) => {
				const category = categories.find((category) => category._id?.toString() === value);

				const ret = {
					...similarProductInfo,
					category,
				};

				if (ret.brandId && ret.category && category && brand) {
					const recognition = getRecognitionByCategoryAndBrand(category, brand);

					if (recognition) {
						ret.recognitionRate = recognition;
					}
				}

				onChange(ret);
			},
			[categories, onChange, brand, similarProductInfo],
		);

		const handleChangeBrand = React.useCallback(
			(value: string) => {
				const brand = brands.find((brand) => brand._id?.toString() === value);

				const ret = {
					...similarProductInfo,
					brandId: brand?._id,
				};

				if (ret.brandId && ret.category && category && brand) {
					const recognition = getRecognitionByCategoryAndBrand(category, brand);

					if (recognition) {
						ret.recognitionRate = recognition;
					}
				}

				onChange(ret);
			},
			[brands, category, onChange, similarProductInfo],
		);

		const handleChangeRecognition = React.useCallback(
			(value: string) => {
				const v = Number(value);

				if (isNaN(v)) {
					return;
				}

				onChange({
					...similarProductInfo,
					recognitionRate: v,
				});
			},
			[onChange, similarProductInfo],
		);

		const maxRate = React.useMemo(() => {
			return getAdCopyMaxRecognition(similarProductInfo, categories, brands);
		}, [similarProductInfo, categories, brands]);

		return (
			<>
				<SelectRow
					disabled={disabled}
					errors={errors}
					id="brandId"
					name="brandId"
					onChange={handleChangeBrand}
					options={brandOptions}
					rowName={adCopySimilarProductParameterLabels.brandId}
					value={castString(similarProductInfo.brandId)}
				/>

				<SelectRow
					disabled={disabled}
					errors={errors}
					id="categoryId"
					name="categoryId"
					onChange={handleChangeCategory}
					options={categoryOptions}
					rowName={adCopySimilarProductParameterLabels.category}
					value={castString(similarProductInfo.category?._id)}
				/>

				<TextRow
					disabled={disabled}
					errors={errors}
					id="recognitionRate"
					name="recognitionRate"
					onChange={handleChangeRecognition}
					rowName={
						<div>
							日本人（当該性の）15～69歳における
							<br />
							商品認知率(%)<AlertText>（{maxRate}％以下の整数で入力）</AlertText>
						</div>
					}
					subText={
						<>
							{brand && <BrandRecognitionTable brand={brand} />}

							{category && <AdCopyCategoryRecognitionTable category={category} />}
						</>
					}
					type="number"
					value={similarProductInfo.recognitionRate?.toString() ?? ""}
				/>
			</>
		);
	},
);
