import * as React from "react";

import type { EvaluationResult } from "../../../../../../../../server/ad-copy-analytics/simulation/lib/evaluations";
import type { AdCopyTestOnlyConceptKey } from "../../../../../../../../server/models/ad-copy/concept";
import type { AdCopyCP } from "../../../../../../../../server/models/ad-copy/cp";
import { AdCopyNormRaw } from "./ad-copy-norm-raw";

const thStyle = {
	width: "152px",
} as const satisfies React.CSSProperties;

type Props<T extends AdCopyTestOnlyConceptKey> = {
	cp: Partial<AdCopyCP> | undefined;
	evaluationResult: EvaluationResult<T>;
	targetConceptType: T;
};

const AdCopyNormTableComponent = <T extends AdCopyTestOnlyConceptKey>({
	cp,
	evaluationResult,
	targetConceptType,
}: Props<T>) => {
	return (
		<table className="result-table norm">
			<thead>
				<tr>
					<th />

					<th colSpan={6}>戦略ターゲットグループ</th>
				</tr>

				<tr>
					<th />

					<th style={thStyle}>n数</th>

					<th style={thStyle}>購入意向ワンナンバースコア(%)</th>

					<th style={thStyle}>リフト</th>

					<th style={thStyle}>差別性（他の商品とは違っている）</th>

					<th style={thStyle}>情報検索意向（この商品についてもっと知りたい）</th>

					<th style={thStyle}>自分向け（自分向けの商品だと思う）</th>
				</tr>
			</thead>

			<tbody>
				<AdCopyNormRaw
					//
					evaluationValues={evaluationResult[targetConceptType]}
					targetConcept={targetConceptType}
				/>

				<AdCopyNormRaw
					//
					evaluationValues={evaluationResult.benchMark}
					targetConcept="benchmark"
				/>

				{(!cp || (cp._id && cp.type === "currentProduct")) && (
					<AdCopyNormRaw
						//
						evaluationValues={evaluationResult.currentProduct}
						targetConcept="currentProduct"
					/>
				)}
			</tbody>
		</table>
	);
};

export const AdCopyNormTable = React.memo(AdCopyNormTableComponent) as typeof AdCopyNormTableComponent;
